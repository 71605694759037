<template>
  <div>
    <bg-modal
      :value="isModalOpen"
      @close="closeModal"
      class="out-of-order-modal"
    >
      <!-- Out Of Order Detail -->
      <div v-if="isShowOutOfOrderDetail">
        <!-- Title -->
        <bg-text size="heading-5">Kamar {{ roomName }}</bg-text>
        <bg-text size="body-4" class="out-of-order-modal__room-type">
          {{ contract.unit_type }}
        </bg-text>

        <!-- Content -->
        <div class="out-of-order-modal__wrap-content">
          <div class="mt-8 flex justify-space-between">
            <div>
              <bg-text size="body-3" class="out-of-order-modal__content-title">
                {{ outOfOrderCategoryLabel }}
              </bg-text>

              <bg-text
                size="body-4"
                class="out-of-order-modal__content-title mt-8 mb-16"
              >
                {{ note }}
              </bg-text>

              <bg-text
                size="body-3"
                class="out-of-order-modal__content-title mb-16"
              >
                {{ startDate }} - {{ endDate }}
              </bg-text>

              <bg-text size="body-4" italic>
                Update terakhir oleh: {{ lastUpdatedBy }} {{ lastUpdatedDate }}
              </bg-text>
            </div>

            <!-- Actions -->
            <div v-if="hasAccessOutOfOrder">
              <bg-dropdown
                role="menu"
                menu-size="fit-to-content"
                menu-placement="bottom-end"
                class="out-of-order-modal__actions-dropdown"
              >
                <template #trigger>
                  <bg-icon
                    name="more-vertical"
                    class="out-of-order-modal__actions-dropdown-trigger"
                  />
                </template>

                <!-- Edit -->
                <bg-dropdown-item role="menuitem" @click="handleEditClicked">
                  <bg-icon name="edit" size="sm" />
                  <span>Edit</span>
                </bg-dropdown-item>

                <!-- Delete -->
                <bg-dropdown-item
                  role="menuitem"
                  @click="openConfirmationModal"
                >
                  <bg-icon name="delete" size="sm" />
                  <span>Delete</span>
                </bg-dropdown-item>
              </bg-dropdown>
            </div>
          </div>
        </div>
      </div>

      <!-- Out Of Order Form -->
      <div v-else-if="hasAccessOutOfOrder" class="out-of-order-form">
        <!-- Title -->
        <bg-text size="heading-5">{{ titleOutOfOrdelForm }}</bg-text>

        <div class="mt-24">
          <validation-observer ref="observer">
            <!-- Category Field -->
            <validation-provider
              name="category"
              rules="required"
              v-slot="{ errors }"
            >
              <bg-field
                label="Kategori"
                :error="!!errors.length"
                :message="errors[0] || ''"
              >
                <bg-select
                  class="out-of-order-modal__select"
                  data-testid="outOfOrderCategory_ddl"
                  v-model="form.category"
                  :options="outOfOrderCategories"
                  placeholder="Pilih kategori Out of Order"
                />
              </bg-field>
            </validation-provider>

            <!-- Note Field -->
            <validation-provider
              name="note"
              :rules="isOtherCategorySelected ? 'required' : ''"
              v-slot="{ errors }"
            >
              <bg-field
                :label="noteLabel"
                label-for="outOfOrderNote_txt"
                :error="!!errors.length"
                :message="errors[0] || ''"
              >
                <bg-input
                  id="outOfOrderNote_txt"
                  v-model="form.note"
                  placeholder="Tulis keterangan Out of Order"
                />
              </bg-field>
            </validation-provider>

            <!-- Date Range Picker -->
            <date-range-picker
              v-model="dates"
              id="outOfOrderDate"
              start-date-label="Tanggal Mulai"
              end-date-label="Tanggal Akhir"
            />
          </validation-observer>
        </div>

        <!-- Save Button -->
        <div class="flex justify-end">
          <bg-button
            :loading="isLoading"
            :disabled="!isValidOutOfOrderFormInput"
            variant="primary"
            class="ml-16"
            @click="handleSave"
          >
            Simpan
          </bg-button>
        </div>
      </div>
    </bg-modal>

    <!-- Confirmation Modal -->
    <bg-modal
      v-model="isShowConfirmationModal"
      :title="`Anda akan menghapus status Out of Order di Kamar ${roomName}`"
      :description="`Status kamar pada periode ${startDate} - ${endDate} akan berubah dari Out of Order menjadi Vacant (tidak terisi).`"
      button-main-text="Lanjut hapus"
      button-second-text="Kembali"
      desktop-size="sm"
      mobile-size="popup"
      @click-second-action="backToOutOfOrderModal"
      @close="backToOutOfOrderModal"
      @click-main-action="deactiveOutOfOrder"
    />

    <!-- Warning Modal -->
    <bg-modal
      v-model="isShowWarningModal"
      title="Tidak dapat tandai “Out of Order”"
      :description="submitErrorMessage"
      button-main-text="OK"
      desktop-size="sm"
      mobile-size="popup"
      @click-main-action="backToOutOfOrderModal"
      @close="backToOutOfOrderModal"
    />

    <!-- Loading Modal -->
    <loading-overlay :value="loadingDeactive || isLoading" />
  </div>
</template>

<script>
import {
  BgModal,
  BgText,
  BgField,
  BgInput,
  BgButton,
  BgSelect,
  BgDropdown,
  BgDropdownItem,
  BgIcon,
} from 'bangul-vue';
import DateRangePicker from '@admin_molecules/DateRangePicker';
import RoomAllotmentApi from '@admin_endpoints/room-allotment';
import {
  dayjs,
  dateFormatterToSend,
  dateFormatterToDisplay,
} from 'Utils/formatter';

import '@admin/pages/RoomAllotmentAddTenant/config/validationRules';
import { ValidationProvider } from 'vee-validate';
import LoadingOverlay from '@molecules/LoadingOverlay';
import {
  OUT_OF_ORDER_CATEGORIES,
  getOutOfOrderCategoryLabel,
} from '../../utils/outOfOrder';

const DEFAULT_ERROR_MESSAGE = 'Terjadi kesalahan silahkan coba lagi.';

export default {
  name: 'OutOfOrderModal',

  components: {
    BgModal,
    BgText,
    BgField,
    BgInput,
    BgButton,
    BgSelect,
    DateRangePicker,
    ValidationProvider,
    LoadingOverlay,
    BgDropdown,
    BgDropdownItem,
    BgIcon,
  },

  props: {
    isModalOpen: {
      type: Boolean,
      required: true,
    },
    contract: {
      type: Object,
      default: () => ({}),
    },
    hasAccessOutOfOrder: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isEdit: false,
      form: {
        category: '',
        note: '',
      },
      dates: {
        startDate: null,
        endDate: null,
      },
      isLoading: false,
      loadingDeactive: false,
      isShowConfirmationModal: false,
      isShowWarningModal: false,
      outOfOrderCategories: OUT_OF_ORDER_CATEGORIES,
      submitErrorMessage: '',
    };
  },

  computed: {
    roomId() {
      return this.contract?.room_id;
    },
    outOfOrderData() {
      return this.contract?.out_of_order_data || {};
    },
    note() {
      return this.outOfOrderData.note || '';
    },
    startDate() {
      return dateFormatterToDisplay(this.outOfOrderData?.start_date);
    },
    endDate() {
      return dateFormatterToDisplay(this.outOfOrderData?.end_date);
    },
    lastUpdatedDate() {
      return dateFormatterToDisplay(this.outOfOrderData?.last_updated_at?.date);
    },
    lastUpdatedBy() {
      return this.outOfOrderData?.last_update_by || '';
    },
    isOutOfOrder() {
      return !!this.contract.is_out_of_order;
    },
    isOtherCategorySelected() {
      return this.form.category === 'other';
    },
    outOfOrderId() {
      return this.contract?.out_of_order_data?.id || 0;
    },
    outOfOrderCategoryLabel() {
      return getOutOfOrderCategoryLabel(this.outOfOrderData?.category);
    },
    isValidOutOfOrderFormInput() {
      const isDatesFilled = !!this.dates.startDate && !!this.dates.endDate;
      const isValidCategory = !!getOutOfOrderCategoryLabel(this.form.category);
      const isValidNoteValue = this.isOtherCategorySelected
        ? !!this.form.note
        : true;

      return isDatesFilled && isValidNoteValue && isValidCategory;
    },
    roomName() {
      return this.contract?.room_name || '';
    },
    isShowOutOfOrderDetail() {
      return this.isOutOfOrder && !this.isEdit;
    },
    titleOutOfOrdelForm() {
      const labelAction = this.isEdit ? 'Ubah' : 'Buat';

      return `${labelAction} status Out of Order Kamar ${this.roomName}`;
    },
    noteLabel() {
      const labelRequired = this.isOtherCategorySelected ? '(wajib)' : '';

      return `Catatan ${labelRequired}`;
    },
  },

  methods: {
    resetState() {
      this.form = {
        category: '',
        note: '',
      };
      this.dates = {
        startDate: null,
        endDate: null,
      };
      this.isEdit = false;
    },

    closeModal() {
      this.$emit('update:isModalOpen', false);
      this.$emit('update:room', {});
      this.resetState();
    },

    handleEditClicked() {
      this.form.category = this.outOfOrderData.category;
      this.form.note = this.outOfOrderData.note;
      this.dates.startDate = dayjs(this.outOfOrderData.start_date).toDate();
      this.dates.endDate = dayjs(this.outOfOrderData.end_date).toDate();
      this.isEdit = true;
    },

    openWarningModal(errorMessage) {
      this.submitErrorMessage = errorMessage;
      this.isShowWarningModal = true;
      this.$emit('update:isModalOpen', false);
    },

    openConfirmationModal() {
      this.isShowConfirmationModal = true;
      this.$emit('update:isModalOpen', false);
    },

    backToOutOfOrderModal() {
      this.isShowConfirmationModal = false;
      this.isShowWarningModal = false;
      this.$emit('update:isModalOpen', true);
    },

    handleSave() {
      if (this.isEdit) {
        this.submitUpdate();
      } else {
        this.submitCreate();
      }
    },

    async deactiveOutOfOrder() {
      if (this.outOfOrderId && !this.loadingDeactive) {
        this.loadingDeactive = true;

        try {
          await RoomAllotmentApi.deactiveOutOfOrder(this.outOfOrderId);

          this.$emit('success-deactivated');
          this.$toast.show('Berhasil menonaktifkan room sebagai out of order.');
          this.closeModal();
        } catch (error) {
          this.$toast.show('Terjadi kesalahan silahkan coba lagi.');
        } finally {
          this.loadingDeactive = false;
          this.isShowConfirmationModal = false;
        }
      }
    },

    getSubmitData() {
      const startDate = dateFormatterToSend(this.dates.startDate);
      const endDate = dateFormatterToSend(this.dates.endDate);

      return {
        ...this.form,
        designer_room_id: this.roomId,
        start_date: startDate,
        end_date: endDate,
      };
    },

    handleSubmitError(error) {
      const { data: responseData, status: responseStatus } =
        error?.response || {};

      const errorMessage = responseData?.error || DEFAULT_ERROR_MESSAGE;

      if (responseStatus === 400 || responseStatus === 404) {
        this.openWarningModal(errorMessage);
      } else {
        this.$toast.show(errorMessage);
      }
    },

    validateForm() {
      return this.$refs.observer.validate();
    },

    showSuccessSubmitToast(category) {
      let categoryLabel = getOutOfOrderCategoryLabel(category);

      if (!categoryLabel || categoryLabel === 'Lainnya') {
        categoryLabel = 'OOO';
      }

      this.$toast.show(
        `Status kamar ${this.roomName} menjadi ${categoryLabel}`
      );
    },

    async submitCreate() {
      const isValid = await this.validateForm();

      if (isValid && this.roomId) {
        const data = this.getSubmitData();

        try {
          this.isLoading = true;
          await RoomAllotmentApi.postOutOfOrder(data);

          this.showSuccessSubmitToast(data.category);
          this.$emit('success-submitted', data.start_date);
          this.closeModal();
        } catch (error) {
          this.handleSubmitError(error);
        } finally {
          this.isLoading = false;
        }
      }
    },

    async submitUpdate() {
      const isValid = await this.validateForm();

      if (isValid && this.outOfOrderId) {
        const data = this.getSubmitData();

        try {
          this.isLoading = true;
          await RoomAllotmentApi.updateOutOfOrder(this.outOfOrderId, data);

          this.$toast.show('Berhasil mengubah data out of order.');
          this.$emit('success-updated', data.start_date);
          this.closeModal();
        } catch (error) {
          this.handleSubmitError(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" src="./OutOfOrderModal.scss" scoped></style>
